<template>
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container flx-row ali-c">
          <el-input
            placeholder="输入会员名称或手机号或会员ID"
            class="search-input"
            clearable
            v-model="searchObj.searchKey"
          ></el-input>
          <el-select
            placeholder="选择会员状态"
            class="search-input ml-15"
            clearable
            v-model="searchObj.status"
          >
            <el-option
              v-for="(item, index) in member_status"
              :key="index"
              :label="item.status"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-select
            placeholder="选择会员等级"
            class="search-input ml-15"
            clearable
            v-model="searchObj.cdmtype"
          >
            <el-option
              v-for="(item, index) in member_grade"
              :key="index"
              :label="item.grade"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-button
            type="primary"
            icon="el-icon-search"
            class="ml-15"
            @click="search"
            >查询</el-button
          >
          <el-button icon="el-icon-refresh" class="ml-15" @click="reset"
            >重置</el-button
          >
        </div>
      </el-col>
    </el-row>
    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <!-- 操作栏 -->
          <div class="flx-row ali-c js-btw">
            <div class="flx-row ali-c">
              <el-button
                class="ml-15"
                type="primary"
                :disabled="multiSeletction.length == 0"
                @click="showInt = true"
                >修改积分</el-button
              >
              <el-button
                class="ml-15"
                type="primary"
                :disabled="multiSeletction.length == 0"
                @click="openCoupon"
                >送优惠券</el-button
              >
            </div>
          </div>
          <!-- 表格 -->
          <el-table
          ref="multipleTable"
            class="mt-24 member-table"
            :data="tableData"
            @selection-change="tableSelect"
          >
            <el-table-column type="selection"></el-table-column>
            <el-table-column label="会员id" prop="cdmcid"></el-table-column>
            <el-table-column label="会员昵称" prop="nickname"></el-table-column>
            <el-table-column label="会员头像" prop="headPortrait">
              <template slot-scope="scope">
                <el-avatar
                  :src="scope.row.headPortrait"
                  shape="square"
                  alt="加载失败"
                ></el-avatar>
              </template>
            </el-table-column>
            <el-table-column label="手机号码" prop="phone"></el-table-column>
            <el-table-column label="会员等级" prop="cdmtype">
              <template slot-scope="scope">
                {{
                  scope.row.cdmtype == 18
                    ? "金卡"
                    : scope.row.cdmtype == 20
                    ? "钻卡"
                     : scope.row.cdmtype == 23
                    ? "铂卡"
                    : "普通"
                }}
              </template>
            </el-table-column>
            <el-table-column label="可用积分" prop="jfye"></el-table-column>
            <el-table-column label="状态" prop="status">
              <template slot-scope="scope">
                <div class="flx-row ali-c">
                  <div
                    class="circle"
                    :class="scope.row.status == 0 ? 'bg-f2' : 'bg-67'"
                  ></div>
                  <span class="ml-5">{{
                    scope.row.status == 0 ? "禁用" : "正常"
                  }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <router-link
                  :to="{
                    name: 'Members_details',
                    params: { id: scope.row.userId },
                  }"
                >
                  <el-link type="primary" :underline="false">
                    <i class="el-icon-info"></i>
                    详情
                  </el-link>
                </router-link>
              </template>
            </el-table-column>
          </el-table>
          <Pagination
            :total="total"
            :pageNum="pageNum"
            :pageSize="pageSize"
            :pageSizes="pageSizes"
             @changePage="search"
            @syncPageData="syncPageData"
          />
        </div>
      </el-col>
    </el-row>
    <!-- 批量修改积分 -->
    <el-dialog title="修改积分" :visible.sync="showInt" width="30%">
      <el-form :model="intForm" label-width="100px" label-position="right">
        <el-form-item
          label="修改积分"
          prop="points"
          :rules="[
            { required: true, message: '数字不能为空' },
            { type: 'number', message: '积分必须为数字值' },
          ]"
        >
          <el-input-number
            v-model.number="intForm.points"
            controls-position="right"
          ></el-input-number>
          <el-popover
            placement="top-start"
            width="250"
            trigger="hover"
            content="输入正数表示增加，负数则减少"
          >
            <i slot="reference" class="el-icon-question c-9"></i>
          </el-popover>
        </el-form-item>
        <el-form-item
          label="备注"
          prop="remark"
          :rules="[{ required: true, message: '备注不能为空' }]"
        >
          <el-input
            v-model="intForm.remark"
            type="textarea"
            placeholder="请输入增减积分原因"
            maxlength="100"
            show-word-limit
            rows="5"
          ></el-input>
        </el-form-item>
        <el-form-item label="操作人员">
          <div>{{ userName }}</div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showInt = false">取 消</el-button>
        <el-button
          type="primary"
          @click="updatePointSelected"
          :disabled="intForm.remark == '' || intForm.points == 0"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 优惠券弹窗 @getTableList="getTableList" -->
    <DepartmentGoodsDialog
      ref="GoodsDialog"
      :tableHeader="tableHeader"
      :searchArr="searchArr"
      :selected="true"
      :keyId="'couponId'"
      @getSelectedList="getSelectedList"

    ></DepartmentGoodsDialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination/index";
import request from "@/utils/request";
import {
  getMemberActivetyList,
  selectCoupon,
  updateIntegral,
  batchReceiveCoupon,
} from "@/api/members/members_manage";
import { getCouponList } from "@/api/maketing/coupons";
import DepartmentGoodsDialog from "@/components/DepartmentGoodsDialog";
export default {
  name: "members_list",
  components: {
    Pagination,
    DepartmentGoodsDialog,
  },
  data() {
    return {
      // 顶部搜索栏数据
      searchObj: {
        searchKey: "",
        status: null,
        cdmtype: null,
      },
      // 会员状态列表,用于筛选
      member_status: [
        {
          status: "正常",
          value: 1,
        },
        {
          status: "禁用",
          value: 0,
        },
        {
          status: "全部",
          value: 2,
        },
      ],
      // 会员等级列表,用于筛选
      member_grade: [
        {
          grade: "金卡",
          value: 18,
        },
        {
          grade: "钻卡",
          value: 20,
        },
         {
          grade: "铂卡",
          value: 23,
        },
        {
          grade: "全部",
          value: 2,
        },
      ],
      // 会员表格数据内容
      tableData: [],
      // 表格多选已选中的数据
      multiSeletction: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
      // 修改积分,优惠券弹窗
      showInt: false,
      // 修改积分form
      intForm: {
        points: "",
        remark: "",
      },
      userName: this.$store.getters.name,
      userId: [],
      tableHeader: [
        // 优惠券列表表头
        {
          value: "优惠券名称",
          key: "name",
        },
        {
          value: "优惠券类型",
          key: "coupon",
        },
        {
          value: "卡券有效期",
          key: "securitiesTime",
        },
        {
          value: "领取条件",
          key: "disbursement",
        },
        {
          value: "剩余张数",
          key: "remainingNumber",
        },
        {
          value: "已领取数量",
          key: "getNumber",
        },
        {
          value: "每人限领",
          key: "limitAmount",
        },
      ],
      searchArr: [
        {
          name: "searchKey",
          value: "",
          placeholder: "优惠券名称",
        },
        {
          name: "couponClass",
          value: "",
          placeholder: "请选择类型",
        }
      ],
      // couponType: [
      //   "",
      //   "通用劵",
      //   "商品劵",
      //   "餐饮优惠券",
      //   "折扣券",
      //   "代金券",
      //   "停车券",
      // ]
    };
  },
  mounted() {
    this.search();
  },
  methods: {
    // 搜索事件
    search() {
      let data = Object.assign(
        { pageNum: this.pageNum, pageSize: this.pageSize },
        this.searchObj
      );
      getMemberActivetyList(data).then((res) => {
        this.tableData = res.data.body.list;
        this.total = res.data.body.total;
      });
    },
    // 重置搜索关键词
    reset() {
      this.searchObj = {
        searchKey: "",
        status: null,
        cdmtype: null,
      };
      this.search();
    },
    syncPageData(data) {
      //会员活动页面大小及页数改变
      this.pageNum = data.num;
      this.pageSize = data.size;
    },
    // 会员多选事件
    tableSelect(e) {
      this.userId = [];
      this.multiSeletction = e;
      // console.log(e);
      e.forEach((mem) => {
        this.userId.push(mem.userId);
      });
    },
    //   获取已勾选优惠券
    getSelectedList(coupons) {
      //子组件传来选中优惠券列表
      // console.log(coupons);
      // console.log(this.multiSeletction);
      let [userIds,couponIds] =[[],[]];
      coupons.forEach((item) => {
        couponIds.push(item.couponId);
      });
      this.multiSeletction.forEach((ele) => {
        userIds.push(ele.userId);
      });

      let params = {
        userIds,
        couponIds,
        couponSource: 2, //类型1领取 2赠送
      };
        batchReceiveCoupon(params).then(res=>{
          this.$message({
            message: "操作成功",
            type: "success"
          })
          this.$refs.multipleTable.clearSelection();
        })
    },
    // getTableList(data) {
    //   //获取优惠券列表
    //   let params = { ...data };
    //   params.status = params.couponStatus;
    //   delete params.couponStatus;
    //   getCouponList(params).then((res) => {
    //     let data = this.$refs.GoodsDialog;
    //     data.total = res.data.body.total;
    //     data.tableData = res.data.body.list;
    //   });
    // },
    // 打开优惠券列表弹窗
    openCoupon() {
      let data = this.$refs.GoodsDialog;
      // console.log(data);
      // data.act_form.list = [];
      // data.selected_temp = JSON.parse(JSON.stringify([]));
      data.showGoods = true;
      data.getGoods(true);
    },
    //赠送优惠券
    // giveCoup(coupId, customerId, index) {
    //   let data = {
    //     customerId: customerId,
    //     couponId: coupId,
    //     amount: 1,
    //     couponSource: 0
    //   }
    //   selectCoupon(data, this.$store.getters.token).then(res => {
    //     if(this.userId.length == index + 1) {
    //       const tip = this.$createElement
    //       this.$message({
    //         message: tip('div', null, [
    //           tip('div', { class: 'mb-5 fs-14 fw-7 c-6' }, '赠送成功'),
    //           tip('div', { class: 'fs-12 c-9' }, '可在用户优惠券明细中查看')
    //         ]),
    //         type: "success"
    //       })
    //     }
    //   })
    // },
    updatePointSelected() {
      //确认修改积分
      this.userId.forEach((id) => {
        this.updatePoint(id);
      });
    },
    updatePoint(userId) {
      //修改积分请求
      let data = {
        points: this.intForm.points,
        remark: this.intForm.remark,
        userId: userId,
      };
      updateIntegral(data).then((res) => {
        this.$message({
          message: "操作成功",
          type: "success",
        });
        this.search();
        this.intForm.points = "";
        this.intForm.remark = "";
        this.showInt = false;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
.tip-text-info {
  color: #409eff;
  cursor: pointer;
}
</style>
